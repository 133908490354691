@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-light-gray: #f4f4f4;
  --primary-gray: #ccd1d9;
  --primary-dark-gray: #666666;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-green: green;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}

/***************** NAVBAR ***********************/

nav {
  z-index: 2;
}

.logo {
  width: 6rem;
  height: 6rem;
}
/**************** MENU LINKS ******************/

nav a.nav-link {
  /* font-weight: 500; */
  font-size: .8rem;
  letter-spacing: .0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: .75rem;
  margin-left: .75rem;
  color: #fff !important;
  color: var(--primary-white) !important;
  border-top: .1875rem solid rgba(255, 255, 255, 0);
  border-top: .1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media(max-width: 768px) {
 .navbar-brand {
   width: 7rem;
 }
}


.navbar-nav > li > a.active,  .navbar-nav > li > a:focus {
  border-top: .1875rem solid #f9ab00;
  border-top: .1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: .1875rem solid #f9ab00;
  border-top: .1875rem solid var(--primary-orange);
}

/***************** HEADER ***********************/

.header-wraper {
  position: relative;
  background: url(../../static/media/background.1e5032d7.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

/***************** RESUME a LINK ***********************/

.resume {
  padding-bottom: .5rem;
  padding-left: .5rem;
}

.resume-a {
  text-decoration: none;
  color: #fff;
  color: var(--primary-white);
  border: .03rem solid ;
  border-color: rgba(255, 255, 255, 0);
  border-color: var(--primary-dark);
  padding: .5rem;
  margin-right: 1.5rem;
}

.resume-a:hover {
  text-decoration: none;
  color: #fff;
  color: var(--primary-white);
  border-color: #f9ab00;
  border-color: var(--primary-orange);
  background-color: #f9ab00;
  background-color: var(--primary-orange);
}
 
/***************** TYPED TEXT ***********************/

.typed-text {
  font-size: 2rem;
  color: #fff;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .typed-text {
    font-size: 1.3rem !important;
  }

  .main-info h1 {
    font-size: 1.7rem !important;
  }
}


/***************** HEADER MAIN OFFER BUTTON ***********************/

.btn-main-offer {
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: .625rem .75rem .75rem .75rem;
  color: #fff;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: #ef4035;
  background-color: var(--primary-red);
  color: #fff;
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/***************** PARTICLES ***********************/

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/***************** ABOUTME ***********************/

.photo_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/**************** EXPERIENCES ******************/

.experience {
  padding: .7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: #f9ab00;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: .125rem;
  height: 100%;
  background: #ccd1d9;
  background: var(--primary-gray);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + .5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;

}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: .3125rem solid #ccd1d9;
  border: .3125rem solid var(--primary-gray);
  background: #f9ab00;
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content{
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-gray);
  direction: ltr;
}

.timeline-content h3{
  margin-top: 0.3125rem;
  color: #ef4035;
  color: var(--primary-red);
}

/**************** EXPERIENCE MEDIA ******************/

@media(max-width:758px){
  .experience .container{
    padding-left:0;
  }
  .experience-wrapper::before{
    left:0.5rem;
    margin-bottom:1.875rem;
  }
  .timeline-block-right{
    float:none;
    width:100%;
  }
  .timeline-block-left{
   float:none;
   direction: ltr;
   width:100%;
 }
}

/**************** PORTFOLIO ******************/

.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-gray);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: #f9ab00;
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  padding: 0 .5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/**************** OVERFLOW BOX ******************/
.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: .2s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #f9ab00;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 7rem;
}

/**************** OVERFLOW BOX ******************/

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 .5rem;
}

.hyper-link {
  cursor: pointer;
  color: #f9ab00;
  color: var(--primary-orange)
}

.hyper-link:hover {
  color: #ef4035;
  color: var(--primary-red)
}

/**************** MEDIA ******************/

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%
  }
}

@media(max-height: 570px) {
  .popupbox-wrapper {
    height: 100%
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90%
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

/**************** CONTACTS ******************/

.contacts {
  background: #666666;
  background: var(--primary-dark-gray);
  padding: 3rem 0;
}

.contacts h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: #f4f4f4;
  color: var(--primary-light-gray);
  font-size: 1.2rem;
}

/**************** INPUTS ******************/

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: .125rem solid #fff;
  border-bottom: .125rem solid var(--primary-white);
  border-radius: 0;
  color: #fff;
  color: var(--primary-white);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: #f9ab00;
  color: var(--primary-orange);
  border-bottom: .125rem solid #f9ab00;
  border-bottom: .125rem solid var(--primary-orange);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -.8rem;
  width: 0;
  height: .125rem;
  background: #f9ab00;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -.3rem 0;
}

textarea.form-control {
  margin: -.3rem 0;
}

.error-message {
  color: #fff;
  color: var(--primary-white);
  background: #f33501;
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: green;
  color: var(--primary-green);
}

/**************** MEDIA ******************/

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 .4rem;
  }
}

/**************** FOOTER ******************/

.footer {
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-gray);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-gray);
}

.footer a:hover {
  text-decoration: none;
  color: #f9ab00;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0; 
}
